import { Meta, Title } from '@solidjs/meta';
import { Heading, Picture, Container, BreadcrumbItem, Page, Section } from '@troon/ui';
import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { useLocation } from '@solidjs/router';
import { getConfigValue } from '../../modules/config';
import { Hero } from '../../components/hero/photo';
import type { RouteDefinition } from '@solidjs/router';

export default function DEIPage() {
	const loc = useLocation();

	return (
		<NoHydration>
			<Title>Senior Executives | Troon</Title>
			<Meta
				name="description"
				content="The senior executives and leadership team Troon and Troon's affiliated brands."
			/>

			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/digital/about/headquarters.jpeg`}
				crop="center"
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Senior Executives</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1" class="text-center text-white">
					<span class="sr-only">Troon </span>Senior Executives
				</Heading>
			</Hero>

			<Page>
				<Container>
					<Section>
						<ul class="grid grid-cols-3 gap-x-4 gap-y-12 sm:grid-cols-4 md:gap-x-8 lg:grid-cols-6">
							<For each={executives}>
								{(person) => (
									<li class="flex flex-col gap-2">
										<Picture
											src={`${getConfigValue('IMAGE_HOST')}/digital/execs/${person.img}`}
											sizes={[[200, 280]]}
											alt={person.name}
											loading="lazy"
											class="w-full rounded"
											crop="face"
										/>
										<p class="flex flex-col gap-px">
											<span class="text-lg font-semibold">{person.name}</span>
											<span class="text-sm">{person.title}</span>
										</p>
									</li>
								)}
							</For>
						</ul>
					</Section>
				</Container>

				<Section appearance="secondary">
					<Container class="flex flex-col gap-8">
						<Heading as="h2">Other officers</Heading>
						<ul class="grid grid-cols-2 gap-x-8 gap-y-1">
							<For each={officers}>
								{(person) => (
									<li>
										<span class="font-semibold">{person.name}</span>
										<span
											// eslint-disable-next-line tailwindcss/no-arbitrary-value
											class="text-sm before:content-['_—_']"
										>
											{person.title}
										</span>
									</li>
								)}
							</For>
						</ul>
					</Container>
				</Section>
			</Page>
		</NoHydration>
	);
}

const executives: Array<{ name: string; title: string; img: string }> = [
	{ name: 'Tim Schantz', title: 'President, Chief Executive Officer', img: 'tim-schantz.jpg' },
	{ name: 'Ruth Engle', title: 'Chief Financial Officer', img: 'ruth-engle.jpg' },
	{ name: 'Bruce Glasco', title: 'Chief Operating Officer', img: 'bruce-glasco.jpg' },
	{ name: 'Jay McGrath', title: 'Chief Legal Officer', img: 'jay-mcgrath.jpg' },
	{ name: 'Mike Ryan', title: 'Chief Operating Officer', img: 'mike-ryan.jpg' },
	{ name: 'Scott Van Newkirk', title: 'Chief Development Officer', img: 'scott-van-newkirk.jpg' },
	{ name: 'Rob DeMore', title: 'Executive Vice President, Troon Privé', img: 'rob-de-more.jpg' },
	{ name: 'Ed Doyle', title: 'President, RealFood', img: 'ed-doyle.jpg' },
	{ name: 'Alex Elmore', title: 'Executive Vice President, Owned-Leased Asset Group Division', img: 'alex-elmore.png' },
	{ name: 'DJ Flanders', title: 'Executive Vice President, Troon International', img: 'dj-flanders.jpg' },
	{ name: 'Phil Green', title: 'Executive Vice President, Managed Properties West', img: 'phil-green.jpg' },
	{ name: 'Gene Hallman', title: 'President, Eventive Sports', img: 'gene-hallman.jpg' },
	{ name: 'Jeff Hansen', title: 'Executive Vice President, General Counsel', img: 'jeff-hansen.jpg' },
	{ name: 'Matt Hurley', title: 'Executive Vice President, Managed Properties East', img: 'matt-hurley.jpg' },
	{ name: 'Jeff Ma', title: 'Chief Digital Officer', img: 'jeff-ma.jpg' },
	{ name: 'Jim McLaughlin', title: 'President, True Club Solutions', img: 'jim-mc-laughlin.jpg' },
	{ name: 'Dave Nicholls', title: 'Executive Vice President, Science & Agronomy', img: 'dave-nicholls.jpg' },
	{ name: 'Chris Nielsen', title: 'Chief Information Officer', img: 'chris-nielsen.png' },
	{ name: 'C.A. Roberts, III', title: 'Mergers & Acquisitions, Business Development', img: 'caroberts.jpg' },
	{ name: 'Allison Sheaves', title: 'Executive Vice President of Human Resources', img: 'allison-sheaves.jpg' },
	{ name: 'Megan Stallone', title: 'Executive Vice President, Controller', img: 'megan-stallone.jpg' },
	{ name: 'Kris Strauss', title: 'Executive Vice President, Global Sales & Marketing', img: 'kris-strauss.jpg' },
	{ name: 'Cindy Anderson', title: 'Senior Vice President, Clubhouse Design & Development', img: 'cindy-anderson.jpg' },
	{ name: 'Jason Barone', title: 'Senior Vice President, Financial Planning & Analysis', img: 'jason-barone.jpg' },
	{ name: 'Ricardo Catarino', title: 'Senior Vice President, Operations', img: 'ricardo-catarino.png' },
	{ name: 'Ron Despain', title: 'Senior Vice President, Golf Course Development', img: 'ron-despain.jpg' },
	{ name: 'Tom Enders', title: 'Senior Vice President, Business Development', img: 'tom-enders.jpg' },
	{ name: 'Charlene Gallob', title: 'Senior Vice President, Procurement', img: 'charlene-gallob.jpg' },
	{ name: 'Mitch Harrell', title: 'Senior Vice President, Operations', img: 'mitch-harrell.jpg' },
	{ name: 'Anne Kaufman', title: 'Senior Vice President, Associate General Counsel', img: 'anne-kaufman.jpg' },
	{ name: 'Phillip Martin', title: 'Senior Vice President, Operations', img: 'phillip-martin.jpg' },
	{ name: 'Matt McIntee', title: 'Senior Vice President, Business Development', img: 'matt-mc-intee.jpg' },
	{ name: 'Darrell Morgan', title: 'Senior Vice President, Operations', img: 'darrell-morgan.jpg' },
	{ name: "Bill O'Brien", title: 'Senior Vice President, Operations', img: 'bill-obrien.png' },
	{ name: 'Jeff Spangler', title: 'Senior Vice President, Science & Agronomy', img: 'jeff-spangler.jpg' },
	{ name: 'Gary Spivey', title: 'Senior Vice President, Finance and Managed Properties', img: 'gary-spivey.jpg' },
];

export { executives };

const officers: Array<{ name: string; title: string }> = [
	{ name: 'Archie Cart', title: 'Vice President, Operations' },
	{ name: 'Carl Bielstein', title: 'Vice President, Operations' },
	{ name: 'Dan Zimmer', title: 'Vice President, Operations' },
	{ name: 'Guy Sugden', title: 'Vice President, Operations' },
	{ name: 'Jeff Thomasson', title: 'Vice President, Operations' },
	{ name: 'Joe Langford', title: 'Vice President, Operations' },
	{ name: 'Justin Herr', title: 'Vice President, Operations' },
	{ name: 'Justin Smith', title: 'Vice President, Operations' },
	{ name: 'Matt Molloy', title: 'Vice President, Operations' },
	{ name: 'Mike Conner', title: 'Vice President, Operations' },
	{ name: 'Nick Bednar', title: 'Vice President, Operations' },
	{ name: 'Ryan Whitney', title: 'Vice President, Operations' },
	{ name: 'Tony Marino', title: 'Vice President, Operations' },
	{ name: 'Jennifer Martin', title: 'Vice President, Human Resources' },
	{ name: 'Megan Clay', title: 'Vice President, Customer Finance' },
	{ name: 'Rob Manning', title: 'Vice President, Global Program Office' },
	{ name: 'Brian Hampson', title: 'Vice President, Science & Agronomy' },
	{ name: 'Simon Doyle', title: 'Vice President, Agronomy' },
	{ name: 'Jeff Schwinkendorf', title: 'Vice President – Casa Verde Golf' },
	{ name: 'Jim DeReuil', title: 'Vice President, Science & Agronomy' },
	{ name: 'Jeff Cathey', title: 'Vice President, Science & Agronomy' },
	{ name: 'Kristen Goulet', title: 'Vice President, Retail' },
	{ name: 'Dolf May', title: 'Vice President, Sales & Marketing' },
	{ name: 'Julia Kelly', title: 'Vice President, Membership Marketing' },
	{ name: 'Brandon Fowler', title: 'Vice President, Business Development' },
	{ name: 'Don Shirey', title: 'Vice President, Business Development' },
	{ name: 'Greg Leisher', title: 'Vice President, Business Development' },
	{ name: 'Joe Goodrich', title: 'Vice President, Business Development' },
	{ name: 'John Bartilomo', title: 'Vice President, Food & Beverage Operations' },
];

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
